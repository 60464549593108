import {AjaxSync} from "../shared/ajax_utilities";
import {displayErrors, modalForm} from "../shared/common";

function resendInfringementLetter($btnList)
{
    const data = $btnList[0].dataset;

    MicroModal.show('modal-letter-resend', {
        onShow: function(modal){

            const $modal = $(modal);
            const $email = $modal.find('#email');

            $modal.find('#letter-info-email').text(data.email);
            $modal.find('#letter-info-date').text(data.date);
            $email.val(data.email).removeClass('error');

            $modal.find('button[data-action="ok"]').unbind().on('click', function(){

                const $btnModal = $(this);

                if( $btnModal.hasClass('ajax') ){
                    console.log('do nothing already sending...');
                    return;
                }

                // change button state
                $btnModal.addClass('ajax');

                const email = $modal.find('#email').val();

                if( !email ){
                    $email.addClass('error');
                    return;
                }

                AjaxSync({
                    url: `${app.CACHE.URL_AJAX}tacho_infringement_letter_resend/run/${data.id}`,
                    method: 'POST',
                    data: { email: $email.val()}
                }, {
                    done: (res) => {
                        // change modal state
                        $btnModal.removeClass('ajax');

                        if( res?.status === 'success' ) {
                            MicroModal.close('modal-letter-resend');

                            // hide list button
                            $btnList.hide();

                            // update sent to column with updated email
                            app.DOM.table.find(`tr#tacho_letters_outstanding_${data.id} td.col_sent_to`).text(res.data);

                        } else if( res?.status === 'error' ) {
                            displayErrors(res, 'modal-letter-resend', false, true);
                        }
                    }
                });
            });
        }
    })
}

$(() => {

    modalForm('modal-letter-resend');

    app.DOM.table.find('.btn-resend').on('click', function(){

        if( $(this).hasClass('ajax') ) {
            return;
        }

        resendInfringementLetter($(this));
    });
});